import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type IProps = {
  prefix?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
};

type ISiteMetaData = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
};

const SEO: React.FC<IProps> = ({
  prefix,
  title,
  description = '',
  imageUrl = '',
}) => {
  const { site }: ISiteMetaData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Helmet title={prefix ? `${prefix} - ${site?.siteMetadata?.title}` : title}>
      <meta name="description" content={description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
    </Helmet>
  );
};

export default SEO;
